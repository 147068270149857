@import "mixins.less";

// Core variables and mixins
@import "../../../node_modules/bootstrap3/less/variables.less";
@import "variables.less";


@import "../../../node_modules/bootstrap3/less/mixins.less";
// Reset and dependencies
@import "../../../node_modules/bootstrap3/less/normalize.less";
@import "../../../node_modules/bootstrap3/less/print.less";
@import "../../../node_modules/bootstrap3/less/glyphicons.less";
// Core CSS
@import "../../../node_modules/bootstrap3/less/scaffolding.less";
@import "../../../node_modules/bootstrap3/less/type.less";
@import "../../../node_modules/bootstrap3/less/code.less";
@import "../../../node_modules/bootstrap3/less/grid.less";
@import "../../../node_modules/bootstrap3/less/tables.less";
@import "../../../node_modules/bootstrap3/less/forms.less";
@import "../../../node_modules/bootstrap3/less/buttons.less";
// Components
@import "../../../node_modules/bootstrap3/less/component-animations.less";
@import "../../../node_modules/bootstrap3/less/dropdowns.less";
@import "../../../node_modules/bootstrap3/less/button-groups.less";
@import "../../../node_modules/bootstrap3/less/input-groups.less";
@import "../../../node_modules/bootstrap3/less/navs.less";
@import "../../../node_modules/bootstrap3/less/navbar.less";
@import "../../../node_modules/bootstrap3/less/breadcrumbs.less";
@import "../../../node_modules/bootstrap3/less/pagination.less";
@import "../../../node_modules/bootstrap3/less/pager.less";
@import "../../../node_modules/bootstrap3/less/labels.less";
@import "../../../node_modules/bootstrap3/less/badges.less";
@import "../../../node_modules/bootstrap3/less/jumbotron.less";
@import "../../../node_modules/bootstrap3/less/thumbnails.less";
@import "../../../node_modules/bootstrap3/less/alerts.less";
@import "../../../node_modules/bootstrap3/less/progress-bars.less";
@import "../../../node_modules/bootstrap3/less/media.less";
@import "../../../node_modules/bootstrap3/less/list-group.less";
@import "../../../node_modules/bootstrap3/less/panels.less";
@import "../../../node_modules/bootstrap3/less/responsive-embed.less";
@import "../../../node_modules/bootstrap3/less/wells.less";
@import "../../../node_modules/bootstrap3/less/close.less";
// Components w/ JavaScript
@import "../../../node_modules/bootstrap3/less/modals.less";
@import "../../../node_modules/bootstrap3/less/tooltip.less";
@import "../../../node_modules/bootstrap3/less/popovers.less";
@import "../../../node_modules/bootstrap3/less/carousel.less";
// Utility classes
@import "../../../node_modules/bootstrap3/less/utilities.less";
@import "../../../node_modules/bootstrap3/less/responsive-utilities.less";

// Ensure the toolbar doesn't sit over the c5 toolbar
.ccm-toolbar-visible .affix {
  top: 50px;
}

//@import "../../../node_modules/bootstrap3/less/bootstrap.less";
// Global Components
html,
body {
  height: 100%;
  width: 100%;
}

body {
  .body-font;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: @theme-primary;
  .transition-all;

  //color: @theme-primary;
  &:hover,
  &:focus {
    color: darken(@theme-primary, 10%);
  }
}

.breadcrumb {
  background: transparent;
}

/* So the navbar remains visible when editing */
.ccm-toolbar-visible {
  .ccm-page {
    margin-top: 48px;
    padding-top: 0 !important;
  }

  .navbar-default.affix {
    top: 48px !important;
  }
}


.ccm-page {
  position: relative;
  background: #fef9f5;
}

.home,
.page-template-landing,
.page-template-landing2,
.page-type-blog-entry,
.page-template-right-sidebar {
  .navbar-default.affix-top {
    background: transparent !important;
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    top: 0
  }
}


.ccm-page {
  font-size: 18px;

  a {}

  hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: fade(@gray-darker, 10%);
  }

  hr.light {
    border-color: white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    .heading-font;
  }


  // p {
  //   line-height: 1.5;
  //   margin-bottom: 20px;
  // }

  .red-pattern-bg {
    background: url("/assets/img/bg-pattern2.svg") no-repeat, @theme-secondary;
    background-size: auto 100%;
    background-position: top right;
  }

  .navbar-default {
    transition: all 0.5s !important;
    border-radius: 0;
    margin-bottom: 0;
    .red-pattern-bg();

    &.affix {
      background: white;
      height: auto;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }

    @media (max-width: 768px) {

      &,
      &.affix-top {
        background: white !important;
        height: auto;
      }
    }

    border-color: fade(@gray-darker, 5%);
    .transition-all;
    .heading-font;

    .navbar-header .navbar-brand {
      padding: 12px 15px;
      .heading-font;
      color: @theme-primary;

      &:hover,
      &:focus {
        color: darken(@theme-primary, 10%);
      }
    }

    .navbar-header .navbar-toggle {
      font-size: 12px;
      color: @gray-darker;
      padding: 8px 10px;
    }

    .ccm-responsive-navigation {
      ul {
        float: left;
        margin: 0;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        >li {
          position: relative;
          display: block;
          float: left;

          >a {

            line-height: 20px;
            position: relative;
            display: block;
            padding: 15px 15px;

            .alt-font;
            font-size: 12px;
          }

          >a,
          >a:focus {
            color: @gray-darker;

            &:hover {
              color: @theme-primary;
            }
          }

          &.nav-selected {

            >a,
            >a:focus {
              color: @theme-primary;
              background-color: transparent;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    @media (min-width: 768px) {
      border-color: transparent;

      .navbar-header .navbar-brand {
        color: fade(white, 70%);

        &:hover,
        &:focus {
          color: white;
        }
      }

      .ccm-responsive-navigation>ul>li {

        >a:focus,
        >a {
          color: fade(white, 70%);

          &:hover {
            color: white;
          }

        }

        &.nav-selected {

          >a,
          >a:focus {
            color: white;
            font-weight: bold;
          }
        }
      }

      .logo-regular {
        display: none;
      }

      &.affix {
        .logo-regular {
          display: block;
        }

        background-color: white;
        border-color: fade(@gray-darker, 10%);

        .navbar-header .navbar-brand {
          color: @gray-darker;

          &:hover,
          &:focus {
            color: @theme-primary;
          }
        }

        .logo-inverse {
          display: none;
        }

        .ccm-responsive-navigation>ul>li>a,
        .ccm-responsive-navigation>ul>li>a:focus {
          color: @gray-darker;

          &:hover {
            color: @theme-primary;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .logo-inverse {
      display: none;
    }

    .navbar-header {
      background: #fff;
    }
  }

  header {
    padding-top: 100px;
  }

  // Homepage Header
  @media (min-width: 768px) {
    header.flex {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  @media (max-width: 540px) {
    .btn {
      white-space: normal;
    }

  }

  .inverse {
    color: white;

    input {
      color: #666;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: white;
    }

    a {
      text-decoration: underline;
      color: white;
    }

    a.btn {
      text-decoration: none;
    }

    .btn.btn-primary {
      background: #ce2626;
      color: #fff;
    }

    .btn {
      color: #666;
    }
  }


  header {
    position: relative;
    width: 100%;
    min-height: auto;
    overflow: hidden;
    padding: 100px 0;
    .red-pattern-bg();
    .inverse();

    .header-bottom {
      border-style: solid;
      border-color: transparent #fef9f5 #fef9f5;
      border-width: 30px 50vw 0;
      position: absolute;
      bottom: 0;
    }

    @media (max-width: 768px) {
      padding: 50px 0;
    }

    .header-content {
      text-align: center;
      padding: 150px 0 50px;

      @media (max-width: 768px) {
        padding-top: 85px;
      }

      //  position: relative;
      .header-content-inner {
        position: relative;
        max-width: 500px;
        margin: 0 auto;

        h1 {
          margin-top: 0;
          margin-bottom: 30px;
          font-size: 30px;
        }

        .list-badges {
          margin-bottom: 25px;

          img {
            height: 50px;
            margin-bottom: 25px;
          }
        }
      }
    }

    .device-container {
      margin: 0 auto 100px;

      .screen img {
        border-radius: 3px;
      }
    }

    @media (min-width: 768px) {
      &.full-height {
        min-height: 100vh;
      }

      .header-content {
        text-align: left;
        padding: 0;

        //height: 100vh;
        .header-content-inner {
          max-width: none;
          margin: 0;
          /*   position: absolute;
             top: 50%;
             transform: translateY(-50%);*/
          margin-top: 125px;

          h1 {
            font-size: 35px;
          }
        }
      }

      .device-container {
        max-width: none;
        max-height: calc(100vh - 100px);
        margin: 100px auto 0;
      }
    }

    @media (min-width: 992px) {
      .header-content .header-content-inner h1 {
        font-size: 50px;
      }
    }
  }

  // Sections


  section {
    padding: 80px 0;


    h2 {
      font-size: 50px;

      @media (max-width: 768px) {
        font-size: 35px;
      }
    }

    @media (max-width: 768px) {
      padding: 50px 0;
    }
  }

  section.compact {
    padding: 30px 0;

    h2 {
      font-size: 50px;

      @media (max-width: 768px) {
        font-size: 35px;
      }
    }

    @media (max-width: 768px) {
      padding: 20px 0;
    }

  }

  section:empty,
  header:empty {
    display: none !important;
  }


  @media (min-width: 768px) {
    .navbar-right {
      margin-right: 15px;
    }
  }

  section#section1_0 {
    p {
      font-size: 24px;

    }

    svg {
      max-width: 100%;
      height: auto;
    }

    padding-bottom: 155px;
    background: #fef9f5;
  }

  #demo-animation3 {
    position: absolute;
    width: 100%;
  }

  section#section1_1 {
    p {
      font-size: 24px;

    }

    padding-top: 155px;

    svg {
      max-width: 100%;
      height: auto;
    }

    background: #3A3737;
    .inverse();
    box-shadow: 0px 10px 50px #222 inset;

    h3 {
      font-size: 30px;
    }
  }

  section#section21 {
    padding: 50px 0;
    background: #f5eae1;
  }

  section.download {
    @media (max-width: 768px) {
      padding: 50px 0;
    }

    position: relative;

    h2 {
      margin-top: 0;
    }

    .badges {
      .badge-link {
        display: block;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          height: 60px;
        }

        @media (min-width: 768px) {
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }

    @media (min-width: 768px) {
      h2 {
        font-size: 70px;
      }
    }
  }

  section.features {
    padding: 90px 0;

    @media (max-width: 768px) {
      padding: 50px 0;
    }

    .section-heading {
      h2 {
        margin-top: 0;
      }

      p {
        margin-bottom: 0;
      }

      margin-bottom: 100px;
    }

    .device-container,
    .feature-item {
      //  max-width: 300px;
      margin: 0 auto;
    }

    .device-container {
      margin-bottom: 100px;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }

    .feature-item {
      text-align: center;
      margin-bottom: 100px;

      h3 {
        font-size: 30px;
      }

      i {
        font-size: 80px;
        background: -webkit-linear-gradient(to left, @theme-secondary, @theme-tertiary);
        background: linear-gradient(to left, @theme-secondary, @theme-tertiary);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    @media (min-width: 992px) {

      .device-container,
      .feature-item {
        max-width: none;
      }
    }
  }


  section#section3_1 {
    background: #3A3737;
    .inverse();
    box-shadow: 0px 10px 50px #222 inset;

    h3 {
      font-size: 30px;
    }
  }

  section.cta {
    position: relative;
    .background-cover;
    background-position: center;
    background-image: url('/assets/img/bg-cta.jpg');
    padding: 250px 0;

    .cta-content {
      position: relative;
      z-index: 1;

      h2 {
        margin-top: 0;
        margin-bottom: 25px;
        color: white;
        max-width: 450px;
        font-size: 50px;
      }

      @media (min-width: 768px) {
        h2 {
          font-size: 80px;
        }
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      background-color: fade(black, 50%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  section.contact {
    text-align: center;

    h2 {
      margin-top: 0;
      margin-bottom: 25px;

      i {
        color: @brand-google-plus;
      }
    }

    ul.list-social {
      margin-bottom: 0;

      li {
        a {
          display: block;
          height: 80px;
          width: 80px;
          line-height: 80px;
          font-size: 40px;
          border-radius: 100%;
          color: white;
        }

        &.social-twitter {
          a {
            background-color: @brand-twitter;

            &:hover {
              background-color: darken(@brand-twitter, 5%);
            }
          }
        }

        &.social-facebook {
          a {
            background-color: @brand-facebook;

            &:hover {
              background-color: darken(@brand-facebook, 5%);
            }
          }
        }

        &.social-google-plus {
          a {
            background-color: @brand-google-plus;

            &:hover {
              background-color: darken(@brand-google-plus, 5%);
            }
          }
        }
      }
    }
  }

  .quote1,
  .quote2 {
    margin-bottom: 20px;
  }

  @media (min-width: 1100px) {
    .quote1 {
      float: right;
      position: relative;
      width: 1063px;
      margin-bottom: 100px;

      &,
      p {
        font-weight: lighter;
        font-size: 28px;
      }

      div.person {
        position: absolute;
        left: 786px;
        top: 264px;
      }

      blockquote {
        background-image: url('/assets/img/quote1.svg');
        margin: 0;
        border-left: none;
        width: 770px;
        height: 558px;
        padding: 40px 135px 0 64px;


      }

    }

    .quote2 {
      position: relative;
      padding-bottom: 160px;

      &,
      p {
        font-weight: lighter;
        font-size: 28px;
      }

      blockquote {
        background-image: url('/assets/img/quote2.svg');
        background-repeat: no-repeat;
        margin: 0;
        border-left: none;
        width: 657px;
        height: 406px;
        padding: 35px 50px;

      }

      div.person {
        position: absolute;
        left: 50px;

        p {
          font-size: 28px;
        }
      }
    }
  }

  &.home footer {
    margin-top: 0;
  }

  #searchResults {
    text-align: left !important;
  }

  footer {
    background-color: @gray-darker;
    padding: 25px 0;
    color: #a7a7a7;
    border-top: 10px solid #6d0e0e;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: fade(white, 80%);
    }

    a {
      color: fade(white, 70%);

      &:before {
        content: "\f061";
        font-family: FontAwesome;
        padding-right: 7px;
        vertical-align: middle;
      }

      &[href^="mailto"]:before {
        display: none
      }
    }

    h4 {
      margin-bottom: 20px;
      font-size: 24px;
    }

    p {
      font-size: 15px;
      margin: 8px 0;
    }

    ul {
      margin-bottom: 0;

      li {
        a {
          font-size: 12px;
          color: fade(white, 70%);

          &:hover,
          &:focus,
          &:active,
          &.active {
            text-decoration: none;
          }
        }
      }
    }
  }

  // Bootstrap Overrides

  .bg-primary {
    background: @theme-primary;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &.btn {
        text-decoration: none;
      }
    }

    .btn-primary {
      background: #ce2626;
    }
  }

  .text-primary {
    color: @theme-primary;
  }

  .no-gutter>[class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  // Button Styles
  .btn.btn-outline {
    color: white;
    border-color: white;
    border: 1px solid;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: white;
      background-color: @theme-primary;
      border-color: @theme-primary;
    }
  }

  .btn {
    .alt-font;
    border-radius: 300px;
    border: none;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  }


  .btn-xl {
    padding: 15px 45px;
    font-size: 24px;
  }

  // Extras
  // -- Highlight Color Customization
  ::-moz-selection {
    color: white;
    text-shadow: none;
    background: @gray-darker;
  }

  ::selection {
    color: white;
    text-shadow: none;
    background: @gray-darker;
  }

  img::selection {
    color: white;
    background: transparent;
  }

  img::-moz-selection {
    color: white;
    background: transparent;
  }

  body {
    webkit-tap-highlight-color: @gray-darker;
  }

  .affix-top {
    .app-buttons {
      .login-button {
        color: #fff
      }
    }
  }

  @media (max-width: 768px) {
    footer {
      padding-bottom: 60px;
    }

  }

  .app-buttons {
    float: right;
    margin-top: 8px;
    margin-right: 8px;

    @media (max-width: 768px) {
      float: none;
      background: #cabfbf;
      padding: 10px;
      width: 100%;
      position: fixed;
      bottom: 0;

      .login-button {
        color: #5a0000 !important;
        float: right;
      }
    }

    .trial-button {
      background: #ce2626;
      border-color: transparent;
    }
  }

  /* The ribbons */

  .corner-ribbon {
    width: 310px;
    background: #e43;
    position: absolute;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 50px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    @media (max-width: 768px) {
      display: none;
    }
  }

  /* Custom styles */

  .corner-ribbon.sticky {
    position: fixed;
  }

  .corner-ribbon.shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  }

  /* Different positions */

  .corner-ribbon.top-left {
    top: 25px;
    left: -50px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .corner-ribbon.top-right {
    top: 60px;
    right: -80px;
    left: auto;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .corner-ribbon.bottom-left {
    top: auto;
    bottom: 25px;
    left: -50px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .corner-ribbon.bottom-right {
    top: auto;
    right: -50px;
    bottom: 25px;
    left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  /* Colors */

  .corner-ribbon.white {
    background: #f0f0f0;
    color: #555;
  }

  .corner-ribbon.black {
    background: #333;
  }

  .corner-ribbon.grey {
    background: #999;
  }

  .corner-ribbon.blue {
    background: #39d;
  }

  .corner-ribbon.green {
    background: #2c7;
  }

  .corner-ribbon.turquoise {
    background: #1b9;
  }

  .corner-ribbon.purple {
    background: #95b;
  }

  .corner-ribbon.red {
    background: #e43;
  }

  .corner-ribbon.orange {
    background: #e82;
  }

  .corner-ribbon.yellow {
    background: #ec0;
  }

  #page_navigation,
  header .nav {
    a {
      text-decoration: none
    }

    padding: 8px 0;

    &#page_navigation {
      background: #343030;
    }

    a {
      color: #fff;

    }

    ol.breadcrumb {
      li.active {
        font-weight: bold;
        color: #fff;
      }
    }

    ul.nav {
      li {
        float: left;

        a:before {
          content: "\f061";
          font-family: FontAwesome;
          padding-right: 7px;
          vertical-align: middle;
          color: lighten(@primary, 20%);
        }

        a:hover,
        a:focus {
          background: @theme-primary;
          color: #fff;
        }
      }
    }
  }
}


section .nav>li>a:before {
  content: "\f061";
  font-family: FontAwesome;
  padding-right: 7px;
  vertical-align: middle;
}

.breadcrumb {
  margin-bottom: 0;
}

section#main {
  padding: 50px 0;
}

section#main:empty {
  display: none;
}


.list-icon {
  .list-unstyled();

  li {
    position: relative;
    padding: 0px 0px 10px 25px;

    &:before {
      color: #F68133;
      font-family: 'FontAwesome';
      position: absolute;
      left: 0;
    }
  }
}


.list-icon-red {
  li {

    &:before {
      color: #c12525 !important;
    }
  }
}

ul.list-check {
  .list-icon();

  li:before {
    content: "\f058";
  }
}


ul.list-arrow {
  .list-icon();

  li:before {
    content: "\f061";
  }
}

.card-box {
  .card-box-heading {
    background: #F7980F;
    font-size: 24px;
    padding: 14px;
    color: #fff;
  }

  background: #fff;
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.25);
  color: #666;
  border-radius: 2px;

  input {
    color: #666;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #5d0f0f;
  }

  a {
    text-decoration: none !important;
    color: #952715 !important;
  }

  a.btn-primary {
    color: #fff !important;
  }


  a.btn {
    text-decoration: none;
  }

  .btn-primary {
    background: #952715;
  }
}

.inner-box {
  padding: 24px;
}

//Hover effects from https://github.com/IanLunn/Hovers
/* Icon Forward */
.hvr-icon-forward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.hvr-icon {
  display: inline-block;

  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-forward:hover .hvr-icon,
.hvr-icon-forward:focus .hvr-icon,
.hvr-icon-forward:active .hvr-icon {
  -webkit-transform: translateX(4px);
  transform: translateX(4px);
}

a.link-arrow {
  .hvr-icon-forward();

  &:before {
    .hvr-icon();
    content: "\f061";
    font-family: FontAwesome;
    padding-right: 7px;
  }

  &:hover {
    &:before {

      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
  }
}


/** Blog listings */
.ccm-block-page-list-page-entry-horizontal {
  padding: 20px 0;
}

div.ccm-block-page-list-page-entry-horizontal div.ccm-block-page-list-page-entry-thumbnail img {
  width: 200px !important;
  border-radius: 10px;
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.1);
}

.page-thumbnail {
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 4px 15px rgba(0, 0, 0, 0.1);
}

.ccm-custom-style-container.ccm-custom-style-headercontent-1766 .pagination a {
  color: #666 !important;
}

/*
 * Row with equal height columns
 * -----------------------------
 */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}