// Mixins

.transition-all() {
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
}

.background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.button-variant(@color; @background; @border) {
	color: @color;
	background-color: @background;
	border-color: @border;
	.transition-all;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		color: @color;
		background-color: darken(@background, 5%);
	    border-color: darken(@border, 7%);
	}
	&:active,
	&.active,
	.open > .dropdown-toggle& {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&:hover,
		&:focus,
		&.focus,
		&:active,
		&.active {
			background-color: @background;
			border-color: @border;
		}
	}

	.badge {
		color: @background;
		background-color: @color;
	}
}

.heading-font {
	font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
	font-weight: 300;
	color: #5d0f0f;
	//letter-spacing: 1px;
}

h4,h5,h6 {
	font-weight: normal;
}

.body-font {
	font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
}

.alt-font {
	font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.5px
	//letter-spacing: 2px;
}